import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import Header from '../components/Header';
import Home from '../pages/home/Home';
import Activiteiten from '../pages/activiteiten/Activiteiten';
import Oogstfeesten from '../pages/oogstfeesten/Oogstfeesten';
import Wildhert from '../pages/wildhert/Wildhert';
import Contact from '../pages/contact/Contact';
import Footer from '../components/Footer/Footer';

import './App.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import '../../styled-components/css/scss/astro-ecommerce.scss'

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#000000',
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Header />
          <div style={{ width: "100%", margin: "auto" }}>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/klj' element={<Activiteiten />} />
              <Route path='/oogstfeesten' element={<Oogstfeesten />} />
              <Route path='/wildhert' element={<Wildhert />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='*' element={<Navigate to="/" replace />} />

            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
