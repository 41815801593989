import React from 'react'

import oogstfeestenImg from "../../assets/oogstfeesten-banner-2024.jpeg"
import contactImg from "../../assets/contact-img.jpeg"
import imgQuiz from "../../assets/img-quiz.jpg"

import AosWrapper from "../../../styled-components/AosWrapper"
import LargeSection from '../../../styled-components/LargeSection';
import GridCardCenter from "../../../styled-components/GridCardCenter";
import TabsOogstfeesten from '../../../styled-components/TabsOogstfeesten'


const Oogstfeesten = () => {

  return (
    <>
      <LargeSection
        title={"Oogstfeesten"}
        full_description={"Oogstfeesten is een meerdaags evenement bestaande uit een quiz, gezellige feestavonden, kindernamiddag en veel meer."}
        pageHeaderBgImg={oogstfeestenImg}
        pageHeaderMinVh="90vh"
      />

      {/* <AosWrapper>
        <div className="container my-5">
          <section className="mb-8">
            <div style={{ border: "1px solid black", paddingTop: "50px", paddingBottom: "50px", borderRadius: "5px"}}>

              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8 col-sm-9 text-center mx-auto">
                    <TabsOogstfeesten />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </AosWrapper>

      <AosWrapper>
        <div className="container my-5">
          <section className="mb-8">
            <div className="page-header py-5 py-md-0" style={{ backgroundImage: 'url(' + imgQuiz + ')', minHeight: "60vh", borderRadius: "5px" }}>
              <span className="mask bg-black opacity-6"></span>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8 col-sm-9 text-center mx-auto">
                    <h1 className="text-white mb-4">Quiz</h1>
                    <p className="lead text-white mb-sm-6 mb-4">De jaarlijkse quiz vindt plaats op <b> vrijdag 16 augustus 2024 om 20u. </b>
                      <br /> Schrijf je snel in via onderstaande link.
                      <br /> <br /> <span style={{ fontSize: '18px', fontWeight: "bold" }}>Opgelet, de plaatsen zijn beperkt!</span>
                    </p>
                    <a target="_blank" href="https://ap.lc/dOIqJ" className="btn btn-white btn-lg">Klik hier om je in te schrijven!</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </AosWrapper> */}

      <div className="container my-5">
        <AosWrapper>
          <div className="container my-5">
            <section className="mb-8">
              <div className="page-header py-5 py-md-0" style={{ backgroundImage: 'url(' + contactImg + ')', minHeight: "60vh", borderRadius: "5px" }}>
                <span className="mask bg-black opacity-6"></span>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 col-sm-9 text-center mx-auto">
                      <p className="lead text-white mb-sm-6 mb-4">Aarzel niet om contact op te nemen voor meer informatie!</p>
                      <a href='/contact' className="btn btn-white btn-lg">Klik hier om ons te contacteren</a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </AosWrapper>
      </div>

    </>
  )
}

export default Oogstfeesten