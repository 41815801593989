import React from "react";

import "./activiteiten.css"

import documentsImg from "../../assets/documents-img.jpeg"
import kampImg from "../../assets/kamp-img.jpeg"
import inschrijvingenImg from '../../assets/inschrijvingen-img.jpeg'
import largeImg from "../../assets/large-img.jpeg"
import fotoAlbumImg from "../../assets/img-fotoalbum.jpg"
import formulierImg from "../../assets/formulierImg.jpeg"
import kampBoekje from "../../assets/Kampboekje-2024-Voeren.pdf"
import kampboekjeImg from "../../assets/kampboekjeImg.jpeg"

import GridCardCenter from "../../../styled-components/GridCardCenter";
import AosWrapper from "../../../styled-components/AosWrapper"
import LargeSection from "../../../styled-components/LargeSection";

const Activiteiten = () => {

  return (
    <>
      <LargeSection
        title={"KLJ"}
        full_description={"Hier vind je meer over activiteiten en algemene informatie. Contacteer ons via het contactformulier voor meer info."}
        pageHeaderBgImg={largeImg}
        pageHeaderMinVh="90vh"
      />

      <AosWrapper>
        <div className="container my-5">
          <section className="mb-8">
            <div className="page-header py-5 py-md-0" style={{ backgroundImage: 'url(' + inschrijvingenImg + ')', minHeight: "60vh", borderRadius: "5px" }}>
              <span className="mask bg-black opacity-6"></span>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8 col-sm-9 text-center mx-auto">
                    <h1 className="text-white mb-4">Inschrijvingen</h1>
                    <p className="lead text-white mb-sm-6 mb-4">Helemaal overtuigd? Schrijf je snel in!</p>
                    <a target="_blank" href="https://forms.gle/DGEBa3pHK9JWESN59" className="btn btn-white btn-lg">Klik hier om jezelf in te schrijven!</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </AosWrapper>

      <div className="container my-5">
        <AosWrapper>
          <div className="d-block text-center mb-5">
            <h3>Activiteiten, fotoalbum, kamp & belangrijke documenten</h3>
            <a className="text-dark font-weight-bold" href="/contact">Aarzel niet om ons te contacteren bij vragen </a>
          </div>
        </AosWrapper>

        <AosWrapper>
          <div className="row mb-5">
            <div className="col-md-12 col-lg-12">
              <GridCardCenter
                thumb_src={largeImg}
                collection={"Klik hier voor informatie over de (aankomende) activiteiten."}
                title={"Activiteiten -16"}
                link="https://drive.google.com/drive/folders/1cNGfjUUcfdbsL7JEGJ7wGnL4KOFUY4cd?usp=sharing"
              />
            </div>
          </div>
        </AosWrapper>

        <AosWrapper>
          <div className="row mb-5">
            <div className="col-md-12 col-lg-12">
              <GridCardCenter
                thumb_src={fotoAlbumImg}
                collection={"Klik hier voor het fotoalbum"}
                title={"Fotoalbum"}
                link="https://drive.google.com/drive/folders/1FGaTPlm7_TJfaC2ADQLbmbxKESyfPO8H?usp=sharing"
              />
            </div>
          </div>
        </AosWrapper>

        <AosWrapper>
          <div className="row mb-5">
            <div className="col-md-12 col-lg-12">
              <GridCardCenter
                thumb_src={documentsImg}
                collection={"Klik hier voor nuttige documenten (Aangifte Ongeval, ...)"}
                title={"Nuttige documenten"}
                link="https://drive.google.com/drive/folders/1KkLFRY_VhrWLZq8cmr4R3Z1NS7Cfi3iE?usp=sharing"
              />
            </div>
          </div>
        </AosWrapper>

        <AosWrapper>
          <AosWrapper>
            <div className="d-block text-center mb-5">
              <h3>Inschrijvingsformulier Kamp</h3>
              <a className="text-dark font-weight-bold" href="/contact">Het kamp is jaarlijks van 4 tot 11 juli. Aarzel niet om ons te contacteren bij vragen.</a>
            </div>
          </AosWrapper>

          <div className="row mb-5">

            <div className="col-md-4 col-lg-4">
              <GridCardCenter
                thumb_src={kampImg}
                collection={"KLJ Kamp - Inschrijving"}
                title={""}
                link="https://docs.google.com/forms/d/e/1FAIpQLSc6R8DtmvwR3NMDIgFMyGgF3FXS4qzhpAruBt4kFArWt-HTNw/viewform"
                subParagraph={true}
                subParagraphText={"Klik om in te vullen"}
              />
            </div>

            <div className="col-md-4 col-lg-4">
              <GridCardCenter
                thumb_src={formulierImg}
                collection={"KLJ Kamp - Medische Fiche"}
                title={""}
                link="https://docs.google.com/forms/d/e/1FAIpQLSdi6gwxO43kWfaGpoZQsjFwiBSvSak-zjh8zo0F_eqo-KnxvA/viewform"

                subParagraph={true}
                subParagraphText={"Klik om in te vullen"}
              />
            </div>

            <div className="col-md-4 col-lg-4">
              <a href={kampBoekje} download="Kampboekje-2024-Voeren">
                <GridCardCenter
                  thumb_src={kampboekjeImg}
                  collection={"KLJ Kamp - Kampboekje"}
                  title={""}
                  subParagraph={true}
                  subParagraphText={"Klik om te downloaden"}
                />
              </a>
            </div>

          </div>
        </AosWrapper>
      </div >

      {/* Lokaal verhuren? */}
      {/* <AosWrapper>
        <div className="container my-5">
          <OfferCards />
        </div>
      </AosWrapper> */}

      <div className="container my-5" >
        <AosWrapper>
          <div className="d-block text-center mb-5">
            <h3>Leiding</h3>
            <a className="text-dark font-weight-bold">
              Meisjesleiding
            </a>
          </div>
          <div className="row mb-5">
            {
              leidersGirls?.map((leider) => {
                return (<div key={leider?.name} className="col-lg-3 col-6">
                  <p className="text-dark font-weight-bold">{leider?.name}</p>
                  <p className="text-dark font-weight">{leider?.role}</p> <br />
                </div>)
              })
            }
          </div>
        </AosWrapper>
        <AosWrapper>
          <div className="d-block text-center mb-5">
            <a className="text-dark font-weight-bold">
              Jongensleiding
            </a>
          </div>
          <div className="row mb-5">
            {
              leidersBoys?.map((leider) => {
                return (<div key={leider?.name} className="col-lg-3 col-6">
                  <p className="text-dark font-weight-bold">{leider?.name}</p>
                  <p className="text-dark font-weight">{leider?.role}</p> <br />
                </div>)
              })
            }
          </div>
        </AosWrapper>
      </div >
    </>
  );
};

export default Activiteiten;

const leidersBoys = [{
  name: "Berre Beernaert",
  role: "Financieel verantwoordelijke"
}, {
  name: "Bert De Backer",
  role: "Leider jongens"
}, {
  name: "Daan Van Loon",
  role: "Leider jongens"
}, {
  name: "Jasper Van den Broek",
  role: "Leider jongens"
}, {
  name: "Lukas Bouckaert",
  role: "Secretaris"
}, {
  name: "Nelles Costermans",
  role: "Leider jongens"
}, {
  name: "Robbe Godrie",
  role: "Leider jongens"
}, {
  name: "Seppe Godrie",
  role: "Technisch verantwoordelijke"
}, {
  name: "Staf Beernaert",
  role: "Leider jongens"
}]

const leidersGirls = [{
  name: "Emilia Van Tichelt",
  role: "Hoofdleider / EHBO verantwoordelijke"
}, {
  name: "Amelie Peeters",
  role: "Leidster meisjes"
}, {
  name: "Anna Bouckaert",
  role: "Lokaal verantwoordelijke"
}, {
  name: "Axelle Van De Velde",
  role: "Leidster meisjes"
}, {
  name: "Cato Konings",
  role: "Leidster meisjes"
}, {
  name: "Hannah Bogaert",
  role: "Leidster meisjes"
}, {
  name: "Jill Broeckx",
  role: "Financieel verantwoordelijke"
}, {
  name: "Julie Van Hooydonck",
  role: "Leidster meisjes"
}, {
  name: "Lore Van Tichelen",
  role: "Hygiënisch verantwoordelijke"
}, {
  name: "Roselie Anthonissen",
  role: "Leidster meisjes"
}]