import AOS from 'aos'
import "aos/dist/aos.css";

import React, { useEffect } from 'react'

const AosWrapper = ({ children }: any) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div data-aos="fade-up">
            {children}
        </div>
    )
}

export default AosWrapper