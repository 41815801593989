import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = [{
  name: "HOME",
  link: "/"
}, {
  name: "KLJ",
  link: "/klj"
}, {
  name: "OOGSTFEESTEN",
  link: "/oogstfeesten"
}, {
  name: "WILDHERT",
  link: "/wildhert"
},{
  name: "CONTACT",
  link: "/contact"
}]

const Header = (props: Props) => {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const navigator = (link: string) => {
    navigate(link)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img onClick={() => navigator("/")} style={{ width: "55px", height: "55px", cursor: "pointer" }} src={process.env.PUBLIC_URL + '/logo/logokljwildert.png'} />
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item?.name} disablePadding onClick={() => navigator(item?.link)}>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item?.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" style={{ position: "static" }}>
        <Toolbar>
          <Box >
            <IconButton
              color="secondary"
              aria-label="open menu"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Typography
            variant="h6"
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            <img onClick={() => navigator("/")} style={{ width: "55px", height: "55px", cursor: "pointer" }} src={process.env.PUBLIC_URL + '/logo/logokljwildert.png'} />
          </Typography>
          <Box sx={{ width: '90vw', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', sm: 'flex' } }}>
            {navItems.map((item) => (
              <Button onClick={() => navigator(item?.link)} key={item?.name} color='secondary' sx={{ color: 'primary' }} >
                {item?.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Header;