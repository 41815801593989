import React from 'react'

const GridCardCenter = ({ thumb_src, title, collection, classList, cta, link, subParagraph = false, subParagraphText }: any) => {
    const classBody = ((cta != null) ? "align-items-end d-flex" : "text-center w-100 pt-8");

    return (
        <>
            <a href={link} target='_blank'>
                <div style={{ height: "350px" }} className={`card card-background align-items-start mb-4 mb-lg-0 ${classList}`}>
                    <div className="full-background" style={{ backgroundImage: `url(${thumb_src})`, backgroundSize: 'cover' }}></div>
                    <div className={`card-body ${classBody}`}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8 col-sm-9 text-center mx-auto">
                                    <h2 className="text-white mb-4">{title}</h2>
                                    <p className="lead text-white mb-sm-6 mb-4">{collection}
                                        {
                                            subParagraph ? <span style={{ fontSize: "14px" }}> <br />{subParagraphText}</span> : null
                                        }
                                    </p>

                                    {/* <a target="_blank" href=" https://docs.google.com/forms/d/e/1FAIpQLSczovKoRe6vjPRblkEIMjsnud8Bps97mzf0QqHuL5KTxdkGsQ/viewform?usp=sf_link" className="btn btn-white btn-lg">Klik hier om jezelf in te schrijven!</a>
                              */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="d-block mt-10">
                            <p className="text-white font-weight-bold mb-1"></p>
                            <h4 className="text-white font-weight-bolder"></h4>
                            <a href={link} className="text-white text-sm font-weight-semibold mb-0">Ontdek &#62;</a>
                        </div> */}
                    </div>
                </div>
            </a>
        </>
    );

}

export default GridCardCenter