import React from 'react'

const GridCard = ({ thumb_src, title, collection, classList, cta, link }: any) => {
    const classBody = ((cta != null) ? "align-items-end d-flex" : "text-center w-100 pt-8");

    return (
        <>
            <a href={link}>
                <div className={`card card-background align-items-start mb-4 mb-lg-0 ${classList}`} style={{ height: "450px" }}>
                    <div className="full-background" style={{ backgroundImage: `url(${thumb_src})`, backgroundSize: 'cover' }}></div>
                    <div className={`card-body ${classBody}`}>
                        <div className="d-block mt-10">
                            <p className="text-white font-weight-bold mb-1">{collection}</p>
                            <h4 className="text-white font-weight-bolder">{title}</h4>
                            <p className="text-white text-sm font-weight-semibold mb-0">Ontdek &#62;</p>
                        </div>
                    </div>
                </div>
            </a>
        </>
    );
}

export default GridCard
