import React from 'react'

import AosWrapper from "../../../styled-components/AosWrapper"
import LargeSection from '../../../styled-components/LargeSection';
import GridCard from '../../../styled-components/GridCard';

import kljImg from "../../assets/klj-img.jpeg"
import logoKlj from "../../assets/logokljwildert.png"
import ofLogo from "../../assets/of-logo.jpg"
import wildhertLogo from "../../assets/wildhertLogo.jpg"
import largeImg from '../../assets/large-img.jpeg'

import "./home.css"

const Home = () => {
  return (
    <>
      <LargeSection
        title={"Welkom op onze website!"}
        full_description={"Hier vind je alle informatie over onze jeugdbeweging, wie we zijn en waar we ons in het jaar mee bezig houden."}
        pageHeaderBgImg={kljImg}
        pageHeaderMinVh="90vh"
      />

      {/*  */}
      <div className="container my-5">
        <AosWrapper>
          <div className="d-block text-center mb-5">
            <h3>Bekijk de belangrijkste paginas</h3>
            <a className="text-dark font-weight-bold" href="/contact">Aarzel niet om ons te contacteren bij vragen </a>
          </div>
        </AosWrapper>

        <AosWrapper>
          <div className="row mb-5">
            <div className="col-md-6 col-sm-12 col-lg-3">
              <GridCard
                thumb_src={logoKlj}
                collection={"Activiteiten & Info"}
                title={"KLJ"}
                link="/klj"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <GridCard
                thumb_src={ofLogo}
                collection={"Jaarlijks feestweekend"}
                title={"Oogstfeesten"}
                link="/oogstfeesten"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <GridCard
                thumb_src={wildhertLogo}
                collection={"Feest voor het goede doel"}
                title={"Wildhert"}
                link="/wildhert"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <GridCard
                thumb_src={largeImg}
                collection={"Contacteer ons bij vragen"}
                title={"Contact"}
                link="contact"
              />
            </div>
          </div>
        </AosWrapper>
      </div >
    </>
  )
}

export default Home