import React from "react";

const Footer = () => {
  return (
    <div>
      <div style={{ textAlign: "center", paddingTop: "2em", paddingBottom: "2em"}}>
        © {new Date().getFullYear()} | KLJ Wildert | All Rights Reserved
      </div>
    </div>
  );
};

export default Footer;
