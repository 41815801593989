import React, { useEffect, useState } from 'react'

import contactImg from "../../assets/contact-img.jpeg"

import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { validate } from 'email-validator';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';

import LargeSection from "../../../styled-components/LargeSection";
import AosWrapper from "../../../styled-components/AosWrapper"

const Contact = () => {
  const getRandomNumber = () => {
    return Math.floor(Math.random() * (5 - 1 + 1) + 1);
  }

  const [number1, setNumber1] = useState<Number>()
  const [number2, setNumber2] = useState<Number>()

  useEffect(() => {
    const randomNumber1 = getRandomNumber();
    setNumber1(randomNumber1)
    const randomNumber2 = getRandomNumber();
    setNumber2(randomNumber2)
  }, [])

  const [message, setMessage] = useState<String>()
  const [messageColor, setMessageColor] = useState<String>()

  const [textName, setTextName] = useState<string>("")
  const [textMail, setTextMail] = useState<string>("")
  const [textMessage, setTextMessage] = useState<string>("")
  const [textCalc, setTextCalc] = useState<string>("")

  const handleName = (e: any) => {
    setTextName(e?.target?.value)
  }
  const handleMail = (e: any) => {
    setTextMail(e?.target?.value)
  }
  const handleMessage = (e: any) => {
    setTextMessage(e?.target?.value)
  }
  const handleCalc = (e: any) => {
    setTextCalc(e?.target?.value)
  }


  const invalidInput = (message: string, color: string) => {
    setTextName("")
    setTextMail("")
    setTextMessage("")
    setTextCalc("")

    const randomNumber1 = getRandomNumber();
    setNumber1(randomNumber1)
    const randomNumber2 = getRandomNumber();
    setNumber2(randomNumber2)

    setMessage(message)
    setMessageColor(color)

  }

  const onSubmit = async () => {
    if (!textName && !textMail && !textMessage && !textCalc) {
      invalidInput("Vul alle velden in...", "red")
      return
    }

    const calc = Number(number1) + Number(number2)
    if (Number(textCalc) !== calc) {
      invalidInput("Berekening is onjuist...", "red")
      return
    }

    if (textMessage?.length < 10) {
      invalidInput("Je bericht is te kort.", "red")
      return
    }

    if (!validate(textMail)) {
      invalidInput("Je e-mail is niet correct", "red")
      return
    }

    else {
      invalidInput("We behandelen je bericht en laten je zo snel mogelijk iets weten.", "green")
    }

    await sendMail(textName, textMail, textMessage)

  }

  return (
    <>
      <LargeSection
        title={"Contact"}
        full_description={"Neem hier contact op met ons bij vragen of opmerkingen."}
        pageHeaderBgImg={contactImg}
        pageHeaderMinVh="90vh"
      />

      <div className="container my-5">
        <AosWrapper>
          <div className="row mb-5">
            <div className="col-md-6 col-sm-12 col-lg-6" style={{ textAlign: "center", paddingTop: "2em", paddingBottom: "2em" }}>
              <p className="text-dark font-weight-bold">Emilia Van Tichelt</p>
              <div style={{ paddingBottom: "1em" }}>
                <PersonIcon />
                <span style={{ paddingLeft: "1em" }}>Hoofdleiding</span>
              </div>
              <div style={{ paddingBottom: "1em" }}>
                <EmailIcon />
                <span style={{ paddingLeft: "1em" }}>emiliavantichelt@gmail.com</span>
              </div>
              <div style={{ paddingBottom: "1em" }}>
                <PhoneIcon />
                <span style={{ paddingLeft: "1em" }}>+32 471 52 50 18</span>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-6" style={{ textAlign: "center", paddingTop: "2em", paddingBottom: "2em"  }}>
              <p className="text-dark font-weight-bold">Lukas Bouckaert</p>
              <div style={{ paddingBottom: "1em" }}>
                <PersonIcon />
                <span style={{ paddingLeft: "1em" }}>Secretaris</span>
              </div>
              <div style={{ paddingBottom: "1em" }}>
                <EmailIcon />
                <span style={{ paddingLeft: "1em" }}>lukasbouckaert@gmail.com</span>
              </div>
              <div style={{ paddingBottom: "1em" }}>
                <PhoneIcon />
                <span style={{ paddingLeft: "1em" }}>+32 472 88 29 24</span>
              </div>
            </div>
          </div>
        </AosWrapper>
      </div >

      <div className="container my-5">
        <AosWrapper>
          <div className="d-block text-center mb-5">
            <h3>Contactformulier</h3>
            <p className="text-dark font-weight-bold">U kan ook steeds een e-mail sturen naar kljwildert@gmail.com</p>
            {
              message ? <p style={{ color: `${messageColor}` }} >{message}</p> : <></>
            }
          </div>
        </AosWrapper>

        <AosWrapper>
          <div className="row mb-5">
            <div className="col-md-6 col-lg-6">
              <p className="text-dark font-weight-bold" style={{ float: "left" }}>Naam:</p>
              <TextField value={textName} onChange={handleName} id="outlined-basic" label="Naam" required variant="outlined" color='secondary' style={{ width: "100%", marginBottom: "1em" }} />
            </div>
            <div className="col-md-6 col-lg-6">
              <p className="text-dark font-weight-bold" style={{ float: "left" }}>E-mail:</p>
              <TextField value={textMail} onChange={handleMail} id="outlined-basic" label="E-mail" required variant="outlined" color='secondary' style={{ width: "100%", marginBottom: "1em" }} />
            </div>
            <div className="col-md-6 col-lg-6">
              <p className="text-dark font-weight-bold" style={{ float: "left" }}>Bericht:</p>
              <TextField
                id="outlined-multiline-flexible"
                label="Bericht"
                multiline
                required
                value={textMessage}
                onChange={handleMessage}
                variant="outlined" color='secondary'
                style={{ width: "100%", marginBottom: "1em" }}
                maxRows={4}
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <p className="text-dark font-weight-bold" style={{ float: "left" }}>Vul de uitkomst van volgende berekening in: {number1?.toString()} + {number2?.toString()}</p>
              <TextField value={textCalc} onChange={handleCalc} id="outlined-basic" label="Uitkomst" required variant="outlined" color='secondary' style={{ width: "100%", marginBottom: "1em" }} />
            </div>
            <div className="col-md-6 col-lg-12" style={{ alignItems: "center", textAlign: "center", justifyContent: "center" }}>
              <br />
              <br />
              <Button onClick={onSubmit} style={{ width: "40%", paddingBottom: "0em", height: "60%" }} color='secondary' variant="outlined">Verzenden</Button>
            </div>

          </div>
        </AosWrapper>
      </div >
    </>
  )
}

export default Contact


const sendMail = (textName: string, textMail: string, textMessage: string) => {
  let data = JSON.stringify({
    "name": textName?.toString()?.trim(),
    "email": textMail?.toString()?.trim(),
    "message": textMessage?.toString()?.trim()
  });

  let config = {
    method: 'POST',
    url: 'https://europe-west1-kljwildert.cloudfunctions.net/mailHandler',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
}