import React from 'react'

import wildhertImg from "../../assets/wildhert.png"
import contactImg from "../../assets/contact-img.jpeg"

import AosWrapper from "../../../styled-components/AosWrapper"
import LargeSection from '../../../styled-components/LargeSection';


const Wildhert = () => {
  return (
    <>
      <LargeSection
        title={"Wildhert"}
        full_description={"Wildhert is een fantastisch en gezellig feest voor het goede doel dat jaarlijks georganiseerd wordt door KLJ Wildert."}
        pageHeaderBgImg={wildhertImg}
        pageHeaderMinVh="90vh"
      />

      <div className="container my-5">
        <AosWrapper>
          <div className="container my-5">
            <section className="mb-8">
              <div className="page-header py-5 py-md-0" style={{ backgroundImage: 'url(' + contactImg + ')', minHeight: "60vh", borderRadius: "5px" }}>
                <span className="mask bg-black opacity-6"></span>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 col-sm-9 text-center mx-auto">
                      <p className="lead text-white mb-sm-6 mb-4">Aarzel niet om contact op te nemen voor meer informatie!</p>
                      <a href='/contact' className="btn btn-white btn-lg">Klik hier om ons te contacteren</a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </AosWrapper>
      </div>
    </>
  )
}

export default Wildhert